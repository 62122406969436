import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentLeft,
    BannerContentRight,
    BannerBtn,
    BannerBtnsWrapper
} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`{
  hostingJson {
    Banner {
      BannerH4
      BannerH3
      BannerPara
      BannerBtn
      BannerImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
    return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentLeft>
                        <Fade delay={200}>
                            <h5>{JSONData.hostingJson.Banner.BannerH4}</h5>
                            <h2>{JSONData.hostingJson.Banner.BannerH3}</h2>
                            <p>{JSONData.hostingJson.Banner.BannerPara}</p>
                        </Fade>
                        <Fade delay={400}>
                            <BannerBtnsWrapper>
                                <BannerBtn href="#">{JSONData.hostingJson.Banner.BannerBtn}</BannerBtn>
                            </BannerBtnsWrapper>
                        </Fade>
                    </BannerContentLeft>


                    <BannerContentRight>
                        <Fade delay={200}>
                            <GatsbyImage
                                image={JSONData.hostingJson.Banner.BannerImage.childImageSharp.gatsbyImageData}
                                alt=""
                                className="BannerImage"
                                imgStyle={{
                                    width:"auto",
                                    right:"0px",
                                    margin:"0px auto"
                                }} />
                        </Fade>
                    </BannerContentRight>

                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;
