import styled from 'styled-components';
import {device} from '../Common/device'

export const KeyFeaturesSection = styled.section`
    padding:100px 0px 60px;
    background:#f9fafc;
    @media ${device.tablet} {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

export const KeyFeaturesCustomContainer = styled.div`
    margin-top:10px;
`;

export const KeyFeaturesCol = styled.div`
    text-align:center;
    margin:10px;
    padding:30px 20px;
    transition:all .5s;

    img{
        height:75px;
        margin-bottom:20px;
    }
    h6{
        color:#3a3a3a;
        line-height: 1;
        margin-bottom: 15px;
    }
    p{
        color:#7a7f83;
        margin-bottom:0px;
    }
`;
