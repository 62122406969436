import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Rubik', sans-serif;
        overflow:hidden;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Oxygen', sans-serif;
        font-weight: 700;
        color:#424345;
        margin-bottom:20px;
    }
    h1{
        font-size:42px;
        line-height:48px;
    }
    h2{
        font-size:36px;
        line-height:42px;
    }
    h3{
        font-size:30px;
        line-height:36px;
    }
    h4{
        font-size:26px;
        line-height:32px;
    }
    h5{
        font-size:24px;
        line-height:30px;
    }
    h6{
        font-size:22px;
        line-height:36px;
    }
    p{
        font-size:15px;
        line-height:1.7;
        margin-bottom:20px;
        color:#7a7f83;

    }
    a{
        font-family: 'Rubik', sans-serif;
        font-size:16px;
        text-decoration:none;
        :hover{
            text-decoration:none;
        }
    }
    form{
        margin-bottom:0px;
    }
    img{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
