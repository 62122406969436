import React from 'react';
import {FeaturesSection,FeaturesRight,FeaturesCard,
FeaturesCardImg,FeaturesRow
} from './featurecards.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            hostingJson {
                Features {
                    DelayConstant
                    FeaturesRight{
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    return(
        <FeaturesSection id="featuresContainer">
            <Container>
                <FeaturesRow>
                    <Col md="12" lg="12">
                        <FeaturesRight>
                            <Row>
                            {
                                JSONData.hostingJson.Features.FeaturesRight.map((item,idx) => {
                                return <Col lg="6" md="6" sm="12">
                                        <Fade delay={(idx+1)*JSONData.hostingJson.Features.DelayConstant}>
                                            <FeaturesCard>
                                                <FeaturesCardImg>
                                                    <img src={item.FeaturesCardIcon} alt=""/>
                                                </FeaturesCardImg>
                                                <h4>{item.FeaturesCardHeading}</h4>
                                                <p>{item.FeaturesCardPara}</p>
                                            </FeaturesCard>
                                        </Fade>
                                    </Col>
                                })
                            }
                            </Row>
                        </FeaturesRight>
                    </Col>
                </FeaturesRow>
            </Container>
        </FeaturesSection>
)
}
export default Features;
