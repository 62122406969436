import React from "react";
import Banner from "../containers/Hosting/Banner";
import HeaderMenuBS from "../containers/Hosting/HeaderMenuBS";
import Footer from "../containers/Hosting/Footer";
import FeatureCards from "../containers/Hosting/FeatureCards";
import KeyFeatures from "../containers/Hosting/KeyFeatures";
import Pricing from "../containers/Hosting/Pricing";
import Contact from "../containers/Hosting/Contact";
import About from "../containers/Hosting/About";
import Faq from "../containers/Hosting/Faq";
import GlobalStyle from "../containers/Hosting/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Hosting/Common/fonts"
import BuyNow from "../components/BuyNow";

const IndexPage = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenuBS/>
       <main>
            <Seo 
                title="Hosting ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy Hosting Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <FeatureCards/>
            <About/>
            <KeyFeatures/>
            <Pricing/>
            <Faq/>
            <Contact/>
        </main>
       <Footer/>
   </div>

)

export default IndexPage;
